<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
var configJson = require("./assets/js/common.json");
export default {
  created(){
  const version = configJson.version; //版本号（每次上线前需要更新下版本号）
    console.log("最新版本号common.js：", version);
    const clientVersion = localStorage.getItem("_version_");
    console.log("当前版本号app.vue：", clientVersion);
    if (clientVersion !== version) {
      console.log("configJson", configJson);
      localStorage.setItem("_version_", version);
      window.location.reload();
    }
  },
  mounted() {
    window.addEventListener("unload", this.saveState);
  },
  methods: {
    saveState() {
      sessionStorage.setItem("state", JSON.stringify(this.$store.state));
    },
  },
};
</script>
<style>
#app {
  height: 100%;
  background-color: #eff2f8;
}

html {
  height: 100%;
  width: 100%;
  background-color: #eff2f8;
}

body {
  margin: 0;
  height: calc(100% - 44px);
  /* height: 100%; */
  width: 100%;
  background-color: #ffffff;
  /*-ms-overflow-style: none;*/
  /*overflow: auto;*/
}
</style>
