export default {
    /**
     * 正式
     */
    // vueDomain: 'https://zbfybjy.fancyinfo.net',
    // apiDomain: 'https://api-zbfybjy.fancyinfo.net/api/',
    // wx_appid: 'wx7a3ffdf9e439b984',
    // qy_appid: 'wwd34f0ff673157b6a',

    /**
     * 测试
     */
    vueDomain: 'https://cuss.fancyinfo.net',
    apiDomain: 'https://api-cuss.fancyinfo.net/api/',
    wx_appid: 'wx36ebe0bc418c2a71',
    // qy_appid: 'ww568f44f1bacd5966',
}