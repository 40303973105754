import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: sessionStorage.getItem('state') ? JSON.parse(sessionStorage.getItem('state')) : {
    roleId: 0,
    hos_id: [],
    hospital: [],
    status: 0,
    administrator: ''
  },
  mutations: {
    CHANGE(state, value) {
      state.roleId = value
    },
    CHANGEHOS(state, value) {
      state.hos_id = value
    },
    HOSTIPAL(state, value) {
      state.hospital = value
    },
    STATUS(state, value) {
      state.status = value
    },
    ROLE(state, value) {
      state.administrator = value
    },
  },
  actions: {
    change(context, value) {
      context.commit('CHANGE', value)
    },
    changeHos(context, value) {
      context.commit('CHANGEHOS', value)
    },
    hospital(context, value) {
      context.commit('HOSTIPAL', value)
    },
    status(context, value) {
      context.commit('STATUS', value)
    },
    role(context, value) {
      context.commit('ROLE', value)
    },
  },
  modules: {}
})