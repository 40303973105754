/**
 * axios配置
 */
import axios from "axios";
import httpUrl from "./httpUrl";

var url = httpUrl.apiDomain;

console.log(httpUrl)
if (process.env.NODE_ENV == "development") {
  axios.defaults.baseURL = url;
} else if (process.env.NODE_ENV == "debug") {
  axios.defaults.baseURL = url;
} else if (process.env.NODE_ENV == "production") {
  axios.defaults.baseURL = url;
}
// 设置统一url
// axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    // 添加token到header
    // eslint-disable-next-line no-irregular-whitespace
    //oXxL-w-FIS4cT6s-W_JFZGPSVNlc    oXxL-w4A6Bgag5ffjXRk76Im8FGY  oXk43s4tRAo-lQrSD-7TuVCoQNK01
    const token = localStorage.getItem("token");
    const openid = localStorage.getItem("openid");
    // const openid = 'oisxE6nF34Mczca2VDuCZSXcLckY';
    
    if (token) {
      config.headers["token"] = token;
    }
    if (openid) {
      config.headers["openid"] = openid;
    }
    // 判断请求的类型
    // 如果是post请求就把默认参数拼到data里面
    // 如果是get请求就拼到params里面
    if (config.method === "post") {
      // if(token){
      // config.transformRequest = [
      //   function(data) {
      //     data.openid = openid;
      //     return qs.stringify(data);
      //   },
      // ];
      // let data = qs.parse(config.data)
      // config.data = qs.stringify({
      //     // token: token,
      //     openid: openid,
      //     ...data
      // })
      // }
    } else if (config.method === "get") {
      // if(token){
      config.params = {
        // token: token,
        openid: openid,
        ...config.params,
      };
      // }
    }

    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  (res) => {
    // 登录过期处理
    // if (res.data.code === 401) {
    //   if (res.config.url === setting.menuUrl) {
    //     store.dispatch('user/setToken').then(() => {
    //       router.push('/login');
    //     });
    //   } else {
    //     MessageBox.alert('登录状态已过期, 请退出重新登录!', '系统提示', {
    //       confirmButtonText: '重新登录',
    //       callback: action => {
    //         if (action === 'confirm') {
    //           store.dispatch('user/setToken').then(() => {
    //             location.replace('/');
    //           });
    //         }
    //       },
    //       beforeClose: () => {
    //         MessageBox.close();
    //       }
    //     });
    //   }
    //   return Promise.reject(new Error(res.data.msg));
    // }
    // token自动续期
    // let access_token = res.headers[setting.tokenHeaderName.toLowerCase()];
    // if (access_token) {
    //   store.dispatch('user/setToken', access_token);
    // }
    return res;
  },
  (error) => {
    return Promise.reject(error);
  }
);
