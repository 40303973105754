import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '*',
    redirect: '/home'
  },
  {
    path: '/monitor',
    name: 'monitor',
    meta: {
      title: '设备监控'
    },
    component: () => import( /* webpackChunkName: "group-tweblive" */ '../views/monitor.vue')
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      title: '多功能自助机管理后台'
    },
    component: () => import( /* webpackChunkName: "group-tweblive" */ '../views/index.vue')
  }, {
    path: '/functionUse',
    name: 'functionUse',
    meta: {
      title: '功能使用'
    },
    component: () => import( /* webpackChunkName: "group-tweblive" */ '../views/functionUse.vue')
  }, {
    path: '/register',
    name: 'register',
    meta: {
      title: '用户注册'
    },
    component: () => import( /* webpackChunkName: "group-tweblive" */ '../views/register.vue')
  }, {
    path: '/chooseHospital',
    name: 'chooseHospital',
    meta: {
      title: '选择医院'
    },
    component: () => import( /* webpackChunkName: "group-tweblive" */ '../views/chooseHospital.vue')
  }, {
    path: '/auditList',
    name: 'auditList',
    meta: {
      title: '注册审核'
    },
    component: () => import( /* webpackChunkName: "group-tweblive" */ '../views/auditList.vue')
  }, {
    path: '/registerAudit',
    name: 'registerAudit',
    meta: {
      title: '注册审核'
    },
    component: () => import( /* webpackChunkName: "group-tweblive" */ '../views/registerAudit.vue')
  }, {
    path: '/complete',
    name: 'complete',
    meta: {
      title: '完成注册'
    },
    component: () => import( /* webpackChunkName: "group-tweblive" */ '../views/complete.vue')
  }, 
  {
    path: '/auth',
    name: 'auth',
    meta:{
      title:'用户登录'
    },
    component: () => import(/* webpackChunkName: "group-tweblive" */ '../views/auth.vue')
  },{
    path: '/administratorRegister',
    name: 'administratorRegister',
    meta:{
      title:'管理员注册'
    },
    component: () => import(/* webpackChunkName: "group-tweblive" */ '../views/administratorRegister.vue')
  },{
    path: '/machineUse',
    name: 'machineUse',
    meta:{
      title:'机器使用'
    },
    component: () => import(/* webpackChunkName: "group-tweblive" */ '../views/machineUse.vue')
  },{
    path: '/transactionData',
    name: 'transactionData',
    meta:{
      title:'交易数据'
    },
    component: () => import(/* webpackChunkName: "group-tweblive" */ '../views/transactionData.vue')
  },{
    path: '/registerRecord',
    name: 'registerRecord',
    meta:{
      title:'注册记录'
    },
    component: () => import(/* webpackChunkName: "group-tweblive" */ '../views/registerRecord.vue')
  },{
    path: '/registerInfo',
    name: 'registerInfo',
    meta:{
      title:'注册信息'
    },
    component: () => import(/* webpackChunkName: "group-tweblive" */ '../views/registerInfo.vue')
  },{
    path: '/spacecp',
    name: 'spacecp',
    meta:{
      title:'个人设置'
    },
    component: () => import(/* webpackChunkName: "group-tweblive" */ '../views/spacecp.vue')
  },{
    path: '/machineList',
    name: 'machineList',
    meta:{
      title:'机器列表'
    },
    component: () => import(/* webpackChunkName: "group-tweblive" */ '../views/machineList.vue')
  },
  {
    path: '/moduleConfiguration',
    name: 'moduleConfiguration',
    meta:{
      title:'模块配置'
    },
    component: () => import(/* webpackChunkName: "group-tweblive" */ '../views/moduleConfiguration.vue')
  },
  
  {
    path: '/machineConfiguration',
    name: 'machineConfiguration',
    meta:{
      title:'机器配置'
    },
    component: () => import(/* webpackChunkName: "group-tweblive" */ '../views/machineConfiguration.vue')
  },
  {
    path: '/moduleEdit',
    name: 'moduleEdit',
    meta:{
      title:'模块配置修改'
    },
    component: () => import(/* webpackChunkName: "group-tweblive" */ '../views/moduleEdit.vue')
  },
  {
    path: '/machineEdit',
    name: 'machineEdit',
    meta:{
      title:'机器配置修改'
    },
    component: () => import(/* webpackChunkName: "group-tweblive" */ '../views/machineEdit.vue')
  },
  {
    path: '/addModule',
    name: 'addModule',
    meta:{
      title:'新增模块配置'
    },
    component: () => import(/* webpackChunkName: "group-tweblive" */ '../views/addModule.vue')
  },
  {
    path: '/addMachine',
    name: 'addMachine',
    meta:{
      title:'新增机器配置'
    },
    component: () => import(/* webpackChunkName: "group-tweblive" */ '../views/addMachine.vue')
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
    
  },
})
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
export default router