import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios";
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import {
    Lazyload
} from 'vant';
import "./config/axios";
import httpUrl from "./config/httpUrl";

Vue.prototype.$httpUrl = httpUrl;
Vue.prototype.$http = axios;

Vue.use(Vant);
Vue.use(Lazyload);
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
    const openid = localStorage.getItem("openid");
    // localStorage.setItem("openid", "oisxE6nF34Mczca2VDuCZSXcLckY");
    if (!openid) {
        if (to.path === "/auth") {
            next();
        } else {
            if (to.path === "/") {
                next("/auth");
                return;
            }
            localStorage.setItem("now_url", to.fullPath); //当前页url与参数放入缓存
            next("/auth");
        }
    } else {
        next();
    }
})
new Vue({
    router,
    store,
    Vant,
    render: h => h(App)
}).$mount('#app')